<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">Sign host history<br>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to areas & groups list"
              @click="$router.push({name: 'researcher.administration.host-history.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div
          v-if="errors != ''"
          class="alert alert-danger mt-1 p-1"
        >
          {{ errors }}
        </div>
        <div class="row">
          <div class="col-12">
            <div
              v-if="admin.roles.includes('super-admin')"
              class="card"
            >
              <div
                v-b-toggle="`collapseResearcher`"
                class="card-header"
              >
                <h3 class="cart-title">
                  <a data-action="collapse">Researcher</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <b-collapse
                :id="`collapseResearcher`"
                visible
              >
                <div class="card-content">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Researcher</label>
                          <input
                            v-model="item.user"
                            disabled
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>

            <div class="card">
              <div
                v-b-toggle="`collapseContent`"
                class="card-header"
              >
                <h3 class="cart-title">
                  <a data-action="collapse">View host history</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <b-collapse
                  :id="`collapseContent`"
                  visible
                >
                  <!-- sólo se muestra "show" si hay texto en el textarea -->
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Host Institution 1</label>
                          <input
                            v-model="item.hostInstitution1"
                            disabled
                            type="text"
                            class="form-control"
                          >
                        </div>
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Department / Group / Unit</label>
                          <input
                            v-model="item.department1"
                            disabled
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Host Institution 2</label>
                          <input
                            v-model="item.hostInstitution2"
                            disabled
                            type="text"
                            class="form-control"
                          >
                        </div>
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Department / Group / Unit</label>
                          <input
                            v-model="item.department2"
                            disabled
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div>
                    </div>
                    <hr>
                    <div class="row">
                      <div class="col-sm-6">
                        <h4>Dates</h4>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="mb-1">
                              <label
                                for=""
                                class="form-label"
                              >Begin date</label>
                              <date-picker
                                v-model="item.begin_date"
                                format="D MMM Y"
                                value-type="format"
                                class="w-100"
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="mb-1">
                              <label
                                for=""
                                class="form-label"
                              >End date</label>
                              <date-picker
                                v-model="item.end_date"
                                format="D MMM Y"
                                value-type="format"
                                class="w-100"
                              />
                              <small>If this field is not filled out, it is interpreted as present</small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <h4>Evaluator</h4>
                        <div class="mb-1">
                          <div class="mb-1">
                            <label
                              for=""
                              class="form-label"
                            >Current evaluator</label>
                            <v-select
                              v-model="item.evaluator"
                              label="name"
                              :options="evaluators"
                              :get-option-key="option => option.id"
                              :reduce="e => e.name"
                              @search="onSearch({name: $event}, 'users/filterEvaluators')"
                            />
                            <!-- <input
                              v-model="item.evaluator"
                              disabled
                              type="text"
                              class="form-control"
                            > -->
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <h4>Scientific contact</h4>
                        <div class="mb-1">
                          <div class="mb-1">
                            <label
                              for=""
                              class="form-label"
                            >Current scientific contact</label>
                            <input
                              v-model="item.interlocutor"
                              type="text"
                              class="form-control"
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr>
                    <div class="row">
                      <div class="col-sm-6">
                        <h4>Signatures</h4>
                        <div class="row">
                          <div class="col-sm-4">
                            <div class="mb-1">
                              <label
                                for=""
                                class="form-label"
                              >Signature date</label>
                              <date-picker
                                v-model="item.signature_date"
                                format="D/M/Y"
                                value-type="format"
                                class="w-100"
                              />
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="mb-1">
                              <label
                                for=""
                                class="form-label"
                              >Signed by ICREA</label>
                              <v-select
                                v-model="item.signed_by"
                                label="name"
                                :options="canSignUsers"
                                :get-option-key="option => option.id"
                                :reduce="e => e.name"
                              />
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="mb-1">
                              <label
                                for=""
                                class="form-label"
                              >Signed by institution</label>
                              <input
                                v-model="item.signed_by_institution"
                                type="text"
                                class="form-control"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <h4>Annex</h4>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="mb-1">
                              <label
                                for=""
                                class="form-label"
                              >Title</label>
                              <input
                                v-model="annexTitle"
                                disabled
                                type="text"
                                class="form-control"
                              >
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="mb-1">
                              <label
                                for=""
                                class="form-label"
                              >Upload annex</label>
                              <div class="input-group">
                                <input
                                  id="formFile"
                                  :ref="`file-annex`"
                                  class="form-control"
                                  type="file"
                                  @change="uploadAnnex"
                                >
                                <a
                                  v-if="item.annex"
                                  id="linkToFile"
                                  :href="item.annex.url"
                                  class="btn btn-outline-primary"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Go to file"
                                  target="_blank"
                                ><i data-feather="external-link" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </b-collapse>
              </div>
            </div>

            <IcreaRemarks
              :item="item"
            />

            <AddFile
              :id="6"
              :title="'Support documentation'"
              :type="4"
              :files="item.new_files"
            />

            <InternalNotes
              :notes="item.notes"
              @saveNewNote="saveNewNote"
            />
          </div>
        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { BCollapse } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import InternalNotes from '@/views/back/partials/components/InternalNotes.vue'
import DatePicker from 'vue2-datepicker'
import IcreaRemarks from '@/views/back/partials/components/IcreaRemarks.vue'
import AddFile from '../../../admin/senior-call/components/AddFile.vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    AddFile,
    InternalNotes,
    IcreaRemarks,
    vSelect,
    DatePicker,
    BCollapse,
  },
  data() {
    return {
      hostHistoryId: this.$route.params.id,
      dateAlert: 0,
      sending: false,
      errors: '',
      annexTitle: '',
      file_description: '',
    }
  },
  computed: {
    ...mapGetters({
      item: 'hostHistories/item',
      statuses: 'status/statuses',
      canSignUsers: 'users/canSignUsers',
      admin: 'auth/admin',
      evaluators: 'users/evaluators',
    }),
  },
  async mounted() {
    if (this.hostHistoryId) {
      await this.$store.dispatch('hostHistories/fetchId', this.hostHistoryId)
      await this.$store.dispatch('users/fetchCanSignUsers')
      if (this.item.annex && this.item.annex.annex_title) {
        this.annexTitle = this.item.annex.annex_title
      }
    }
  },
  methods: {
    saveFiles(files) {
      this.item.new_files = files.data
    },
    uploadAnnex() {
      this.annexTitle = `annex-${this.item.user.replaceAll(' ', '-').toLowerCase()}-${this.item.begin_date.replaceAll(' ', '-').toLowerCase()}-${this.item.code ? this.item.code : ''}`
      const file = this.$refs['file-annex'].files[0]
      this.item.annex.data = {
        name: this.annexTitle,
        original_name: file.name,
        description: this.file_description,
        weight: (file.size / 1024 / 1024).toFixed(2),
        extension: file.type,
        file,
        file_type: this.type,
        url: URL.createObjectURL(file),
      }
      this.item.annex.annex_title = this.annexTitle
      this.item.annex.url = URL.createObjectURL(file)
    },
    async save() {
      this.sending = true
      try {
        if (this.hostHistoryId) {
          await this.$store.dispatch('hostHistories/update', { id: this.hostHistoryId, data: this.item })
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.item && this.item.id) {
        Vue.swal('The Host history has been saved correctly!', '', 'success').then(() => {
          this.$router.push({ name: 'researcher.administration.host-history.index' })
        })
      } else {
        // Vue.swal
      }

      this.sending = false
    },
    saveNewNote(note) {
      this.item.notes.push(note)
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
  },
}
</script>
